import React, { useEffect, useState } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, Content, Toggle, ToggleWrapper } from "../styles";
import { ThemeDefinition, TimerState } from "../../../types";

type PropsType = {
    theme: ThemeDefinition,
    timer: TimerState,

    toggleTimer: () => void,
    setRandomTheme: () => void,
    setTimerTiming: (timing: number) => void,
    setTimerCallback: (callback: TimerHandler) => void,
}

const Header: React.FC<PropsType> = (props: PropsType) => {
    const {
        timer,

        toggleTimer,
        setRandomTheme,
        setTimerCallback
    } = props

    const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSettingsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setSettingsVisible(!settingsVisible);
    }

    const onTimerClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        toggleTimer();
    }

    useEffect(() => {
        setTimerCallback(setRandomTheme)
    }, [setTimerCallback, setRandomTheme])

    return (
        <Wrapper>
            <Content>
                <ToggleWrapper>
                    <Toggle type="button"
                        isActive={!!timer.timer}
                        onClick={onTimerClick}>
                        {timer.timing} ms
                        <FontAwesomeIcon icon="clock" />
                    </Toggle>
                </ToggleWrapper>
            </Content>
        </Wrapper>
    )
}

export default Header;