import styled from "styled-components"

export const Toggle = styled.button<{ isActive?: boolean }>`
    background-color: transparent;
    font-size: 1.5rem;
    mix-blend-mode: difference;
    color: #fff;
    opacity: ${(props) => props.isActive ? '1' : '.5'};
    cursor: pointer;
    border: 0;
    box-shadow: none;
    outline: none;
    svg {
        color: inherit;
        isolation: isolate;
    }
`

export const ToggleWrapper = styled.div`
    margin-left: auto;
    ${Toggle} + ${Toggle} {
        margin-left: 0.5rem;
    }
`

export const Content = styled.div`
    margin: 0 auto;
    max-width: 1200px;
    width: 100%;
    display: flex;
    align-items: center;
`

export const Wrapper = styled.header`
    height: 3rem;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    width: 100%;
    z-index: 2;
`