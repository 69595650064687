import { TIMER_SET_TIMING, TIMER_TOGGLE, TIMER_SET_CALLBACK } from './../../store/actions/timer';
import { THEME_RANDOM } from '../../store/actions/theme';
import { Dispatch } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import Header from "./container";

const mapStateToProps = (state: any) => ({
    theme: state.theme,
    timer: state.timer
});

const mapActionsToProps = (dispatch: Dispatch<AnyAction>) => ({
    toggleTimer: () => dispatch({ type: TIMER_TOGGLE }),
    setRandomTheme: () => dispatch({ type: THEME_RANDOM }),
    setTimerTiming: (timing: number) => dispatch({ type: TIMER_SET_TIMING, payload: timing }),
    setTimerCallback: (callback: TimerHandler) => dispatch({ type: TIMER_SET_CALLBACK, payload: callback }),
});

export default connect(mapStateToProps, mapActionsToProps)(Header);

