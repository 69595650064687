import { ThemeDefinition } from './../types/index';

export function getRandomTheme(): ThemeDefinition {
    return {
        backgroundColor: getRandomColor(),
        hashtagColor: getRandomColor(),
        textColor: getRandomColor()
    };
}

export function getRandomColor(): string {
    return `#${Math.random().toString(16).substr(2,6)}`;
}