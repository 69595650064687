import { AnyAction } from 'redux';
import { getRandomTheme } from '../../utils';
import { THEME_SET, THEME_SET_BACKGROUND_COLOR, THEME_SET_TEXT_COLOR, THEME_SET_HASHTAG_COLOR, THEME_RANDOM } from '../actions';
import { ThemeDefinition } from './../../types/index';

export function themeReducer(
    state: ThemeDefinition = getRandomTheme(),
    action: AnyAction
): ThemeDefinition {
    if (!action.type) return state
    switch (action.type) {
        case THEME_SET_BACKGROUND_COLOR:
            return {
                ...state,
                backgroundColor: action.payload
            };
        case THEME_SET_TEXT_COLOR:
            return {
                ...state,
                textColor: action.payload
            };
        case THEME_SET_HASHTAG_COLOR:
            return {
                ...state,
                hashtagColor: action.payload
            };
        case THEME_RANDOM:
            return getRandomTheme();
        case THEME_SET:
            return action.payload;
        default:
            return state;
    }
}