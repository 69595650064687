import styled from "styled-components"

export const Hashtag = styled.span`
`

export const Claim = styled.h2`
    margin: 0;
    font-size: 7vmax;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    user-select: none;
`

export const Wrapper = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`