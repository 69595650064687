import React from 'react';
import Meta from "./meta";
import RootView from "./views";

const App: React.FC<any> = (props: any) => {
  return (
    <>
      <Meta />
      <RootView />
    </>
  );
}

export default App;
