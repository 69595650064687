import React, { useContext } from "react"
import { Wrapper, Claim, Hashtag } from "./styles"
import { ThemeContext } from 'styled-components';
import { ThemeDefinition } from "../../types";

type PropsType = {}

const Banner: React.FC<PropsType> = (props: PropsType) => {
    const themeContext = useContext<ThemeDefinition>(ThemeContext);

    return (
        <Wrapper style={{ backgroundColor: themeContext.backgroundColor }}>
            <Claim style={{ color: themeContext.textColor }}>
                <Hashtag style={{ color: themeContext.hashtagColor }}>#</Hashtag>
                SentitiLibero
            </Claim>
        </Wrapper>
    )
}

export default Banner;