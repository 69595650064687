import React from "react"
import { Helmet } from "react-helmet";

const Meta: React.FC<any> = () => {
    return (
        <Helmet>
            <title>Sentiti Libero</title>
            <meta name="description" content="Sentiti Libero" />

            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        </Helmet>
    )
}

export default Meta;