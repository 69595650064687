import { Dispatch } from "react";
import { connect } from "react-redux";
import { AnyAction } from "redux";
import { THEME_SET, TIMER_TIMING_DECREASE, TIMER_TIMING_INCREASE } from "../store/actions";
import { ThemeDefinition } from "../types";
import RootView from "./container";

const mapStateToProps = (state: any) => ({
    theme: state.theme
});

const mapActionsToProps = (dispatch: Dispatch<AnyAction>) => ({
    decreaseTiming: () => dispatch({ type: TIMER_TIMING_DECREASE }),
    increaseTiming: () => dispatch({ type: TIMER_TIMING_INCREASE }),
    setTheme: (theme: ThemeDefinition) => dispatch({ type: THEME_SET, payload: theme }),
});

export default connect(mapStateToProps, mapActionsToProps)(RootView);

