import { combineReducers, createStore } from "redux"
import { themeReducer } from "./reducers/theme"
import { timerReducer } from "./reducers/timer"

export function createAppStore() {
    const appReducers = combineReducers({
        theme: themeReducer,
        timer: timerReducer
    });

    return createStore(appReducers);
}