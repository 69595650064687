import React, { useEffect } from "react";
import { ThemeProvider } from 'styled-components';
import { ThemeDefinition } from "../../types";
import { getRandomTheme } from "../../utils";
import Header from "../../components/header";
import Banner from "../../components/banner";

type PropsType = {
    theme: ThemeDefinition,

    increaseTiming: () => void,
    decreaseTiming: () => void,
    setTheme: (theme: ThemeDefinition) => void,
};

const RootView: React.FC<PropsType> = (props: PropsType) => {
    const {
        theme,
        increaseTiming,
        decreaseTiming,
        setTheme
    } = props;

    const setRandomTheme = () => {
        setTheme(getRandomTheme());
    }

    const onWindowKeyup = (e: KeyboardEvent) => {
        const { key } = e
        switch (key) {
            case " ":
                setRandomTheme();
                break;
            case "ArrowUp":
                increaseTiming();
                break;
            case "ArrowDown":
                decreaseTiming();
                break;
        }
    }

    const onWindowMousedown = (e: MouseEvent) => {
        setRandomTheme();
    }

    useEffect(() => {
        window.addEventListener('mousedown', onWindowMousedown);
        window.addEventListener("keyup", onWindowKeyup);

        return () => {
            window.removeEventListener('mousedown', onWindowMousedown);
            window.removeEventListener("keyup", onWindowKeyup);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Header {...props} />
            <Banner />
        </ThemeProvider>
    )
}

export default RootView;