import { TIMER_SET_INTERVAL, TIMER_SET_TIMING, TIMER_UNSET_INTERVAL, TIMER_SET_CALLBACK, TIMER_TOGGLE, TIMER_TIMING_INCREASE, TIMER_TIMING_DECREASE } from './../actions/timer';
import { TimerState } from './../../types/index';
import { AnyAction } from "redux";

const initialTimerState = (): TimerState => ({
    timing: 250,
    timer: null,
    timerCallback: () => { }
})

export function timerReducer(
    state: TimerState = initialTimerState(),
    action: AnyAction
): TimerState {
    if (!action.type) return state
    switch (action.type) {
        case TIMER_TOGGLE:
            if (!state.timerCallback) return state;
            if (state.timer) {
                clearInterval(state.timer);
                return {
                    ...state,
                    timer: null
                }
            } else {
                return {
                    ...state,
                    timer: setInterval(state.timerCallback, state.timing)
                }
            }
        case TIMER_SET_INTERVAL:
            if (!state.timerCallback) return state;
            return {
                ...state,
                timer: setInterval(state.timerCallback, state.timing)
            }
        case TIMER_SET_TIMING:
            return {
                ...state,
                timing: action.payload
            }
        case TIMER_UNSET_INTERVAL:
            if (!state.timer) return state;
            clearInterval(state.timer);
            return {
                ...state,
                timer: null
            };
        case TIMER_SET_CALLBACK:
            return {
                ...state,
                timerCallback: action.payload
            }
        case TIMER_TIMING_INCREASE:
            if (!state.timerCallback || !state.timer) return state;
            clearInterval(state.timer);
            return {
                ...state,
                timer: setInterval(state.timerCallback, state.timing),
                timing: (state.timing + 10)
            }
        case TIMER_TIMING_DECREASE:
            if (!state.timerCallback || !state.timer || state.timing === 10) return state;
            clearInterval(state.timer);
            return {
                ...state,
                timer: setInterval(state.timerCallback, state.timing),
                timing: (state.timing - 10)
            }
        default:
            return state;
    }
}